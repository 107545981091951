import React from 'react'
import Layout from '../components/layout'
import ViewBox from '../components/viewbox'
import Contact from '../components/contact'
import smiley from '../images/Smiley.svg'
import minimize from '../images/Minimize.svg'
import close from '../images/Close.svg'
import SEO from '../components/seo'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/core'
import facepaint from 'facepaint'

const breakpoints = [599, 600, 799, 800, 999, 1000] 

const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

const mq2 = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

const WindowFrame = styled.div`
  background-color: #181818;
	padding-bottom: 1vh;
	display: block;
	box-sizing: border-box;
	margin: 2vh auto;
	-moz-box-shadow:    10px 10px 0px 0px #2E6365;
 	-webkit-box-shadow: 10px 10px 0px 0px #2E6365;
  box-shadow:         10px 10px 0px 0px #2E6365;
`

const CloseIcons = styled.div`
	float: right;
	padding: 0;
	margin: .7vh 1em;

  .closeIcon {
  	margin-left: 1em;
    margin-bottom: 0;
  }

  .minimizeIcon {
    margin-bottom: 0;
  }
`

export default () => (
  <Layout>
    <SEO title="web & mobile developer" keywords={[`developer`, `app`, `android`, `demoscene`]} />

  <div class="wrapper">
    <WindowFrame css={mq({
       width: ['72vw','90vw', '90vw', '88vw', '45em'] 
      })}>
      <div class="windowBar">
        <img class="smileyIcon" src={smiley} alt="Smiley Icon" />
        <h4>ANDREW CABRERA - Android Developer</h4>
        <CloseIcons css={css`
            ${mq2[0]} {
              display: none;
            }
          `}>
          <img class="minimizeIcon" src={minimize} alt="Minimize Icon" />
          <img class="closeIcon" src={close} alt="Close Icon" />
        </CloseIcons>
      </div>

    <ViewBox />
    <Contact />
  </WindowFrame>

</div>

  </Layout>
)

import React,  { Component } from 'react'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import styled from '@emotion/styled'
import facepaint from 'facepaint'


import * as Yup from 'yup'

import '../styles/global.css'


const breakpoints = [599, 600, 799, 800, 999, 1000] 

const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

const signUpSchema = Yup.object().shape({
  email: Yup.string()
  .email('invalid email')
  .required('email required'),
  message: Yup.string()
  .min(30, 'message too short')
  .required('message required')
})


class Contact extends Component {
  //process form submission here
  //done submitting, set submitting to false
  render(){
    return (
      <div id="contactBox">
          <Formik
            initialValues={{
              email: '',
              message: ''
            }}
              validationSchema={signUpSchema}
              onSubmit={(values, actions) => {
              this.setState({ messageSending: true })
              axios({
                method: "POST",
                url: "https://formspree.io/mayyanre",
                data: values
              })
              .then(response => {
                actions.setSubmitting(false)
                actions.resetForm()
                this.setState({messageSending: true})
              })
              .catch(error => {
                actions.setSubmitting(false)
                this.setState({messageSending: false, error})
              })
              return
            }}
          >

            {({values, handleSubmit, touched, errors, isSubmitting, status, isValid }) => (
              <Form id='contact-form' noValidate>
                <div>
                  <label for="message">Email:</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    label="Email: "
                    value={values.email}
                    placeholder="hi@andrew.cab"
                  />
                  {errors.email && touched.email ? (
                    <li class="formAlert" style={{ color: '#F66464' }}>{errors.email}</li>
                  ) : null}

                </div>

                <div>
                  <label for="message">Subject:</label>
                  <Field
                    type="message"
                    name="message"
                    id="message"
                    component="textarea"
                    value={values.message}
                    placeholder="Contact Studio v5.3" 
                  />
                  {errors.message && touched.message ? (
                    <li class="formAlert" style={{ color: '#F66464' }}>{ errors.message }</li>
                  ) : null}
                </div>
                <div>
                  <label for="note">Serial Number:</label>
                  <input type="text" id="note" name="note" disabled placeholder="VKE018IDXXWJQP8IOJC12PCX7NEN"/>
                </div>

                <button type="submit" disabled={isSubmitting}> {isSubmitting ? "Submitting.." : "Submit"} </button>

                <button type="submit"> Generate </button>
              </Form>
            )}
          </Formik>
        </div>
    )
  }
}

export default Contact

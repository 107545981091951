import React from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';


class ViewBox extends React.Component {
  state = {}
  componentDidMount() {
    this.render3DText()
    this.mql = window.matchMedia(`(min-device-width: 90em)`)
    this.mql.addListener(this.handleSizeChange)
  }

  handleSizeChange = () => {
      const width = this.container.clientWidth;
      const height = this.container.clientHeight;
      if (this.container.width !== width ||this.container.height !== height) {
      this.renderer.setSize(width, height)
      this.camera.aspect = width / height
      this.camera.updateProjectionMatrix()
    }
  }

  render3DText = () => {
    const container = document.getElementById('viewBox')
    this.container = container
    const aspectRatio = container.innerWidth / container.innerHeight

    // Position the Camera
    const camera = new THREE.PerspectiveCamera(
      50,
      aspectRatio,
      1,
      1000
    )
    this.camera = camera

    // Create the scene
    const scene = new THREE.Scene()
    const dirLight = new THREE.DirectionalLight(0xffffff, 0.7)
    dirLight.position.set(310, 280, 600)
    scene.add(dirLight)
    const dirLight2 = new THREE.DirectionalLight(0xffffff, 0.65)
    dirLight2.position.set(-320, 475, -125)
    scene.add(dirLight2)
    const hemiLight = new THREE.AmbientLight(0x8493ff, 0.6)
    hemiLight.position.set(0, 300, 0)
    scene.add(hemiLight)

    // Renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer = renderer
    renderer.setPixelRatio(window.devicePixelRatio)
    container.appendChild(renderer.domElement)
    renderer.gammaInput = true
    renderer.gammaOutput = true

    var object

    const loader = new GLTFLoader().setPath('./model/')

    loader.load(
      'AndrewLogo.gltf',
      data => {
        object = data.scene
        object.traverse(node => {
          if (node.isMesh) {
            node.material.roughness = 0.4
            node.material.metalness = 0.3
          }
        })
        // onWindowResize()
        object.scale.set(1, 1, 1)
        // this.object.position.x = 0
        object.position.z = -5
        object.position.x = -.1
        object.position.y = -.8
        scene.add(object)
        this.handleSizeChange()
      },
      error => console.error(error)
    )
    
    const speed = 0.005
    const render3DText = () => {
      if(object){
       object.rotation.y -= speed * 2 
      }
    }

    // Animate Function
    const animate = () => {
      requestAnimationFrame(animate)
      render()
    }
    // Render Function
    function render() {
      renderer.render(scene, camera)
      render3DText()
    }
    requestAnimationFrame(animate)
  }
  render() {
    return <div class="renderLogo" id="viewBox" ref={mount => {this.mount = mount}}/>
  }
}
export default ViewBox
